import React, {useState, useRef} from 'react';
import classNames from 'classnames';
import '../../assets/customStyles/styles.css';

import {useField, useFormik} from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core/styles";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from "@mui/material/FormControl";
import Button from '@mui/material/Button';
import { API } from 'aws-amplify'

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required').min(0),
    subject: yup
        .string('Enter your message subject')
        .max(100, 'Subject must be less than 100 characters')
        .required('Subject is required'),
    type: yup.string()
        .oneOf(
            ['dataset', 'download', 'devkit', 'benchmark', 'bugs', 'other'],
            'Invalid Job Type'
        )
        .required('Required'),
    message: yup.string()
        .max(2000, 'Message must be 2000 characters or less')
        .required('Required'),
});
const Contact = ({
                            className,
                            topOuterDivider,
                            bottomOuterDivider,
                            topDivider,
                            bottomDivider,
                            hasBgColor,
                            invertMobile,
                            invertDesktop,
                            invertColor,
                            alignTop,
                            imageFill,
                            ...props
                        }) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        className
    );


    const formik = useFormik({
        initialValues: {
            email: '',
            subject: '',
            type: '',
            message: ''
        },
        validationSchema: validationSchema,
        onSubmit: async () => {
            const data = {
                body: {
                    email: formik.values.email,
                    type: formik.values.type,
                    subject: formik.values.subject,
                    message: formik.values.message
            }}
            alert('Sent!')
            console.log(data)
            const apiData = await API.post('contactform', '/contactform', data)
            console.log(apiData)
        },
    });

    const useStyles = makeStyles((theme) => ({
        root: {
            '& .MuiTextField-root': {
                color: '#9CA9B3',
                marginTop: '20px',
                marginBottom: '20px',
                width: '40vw'
            },
            '& .MuiFormLabel-root': {
                color: '#9CA9B3',
                fontFamily: 'Inter'
            },
            '& .MuiInputBase-root': {
                color: '#9CA9B3',
                fontFamily: 'Inter'
            },
            '& .MuiFormHelperText-root': {
                color: '#9CA9B3',
                fontFamily: 'Inter'
            },
            '& .MuiInput-underline::before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.7)',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                },
            },
            '& .MuiSvgIcon-root': {
                color: 'white',
            }
        },
    }))
    const classes = useStyles();
    const [selection, setSelection] = React.useState('');
    const handleChange = (event) => {
        setSelection(event.target.value);
        formik.values.type = event.target.value;
    };
    return (
            <section {...props} className={outerClasses}>
                <div className="container">
            <form className={classes.root} onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    placeholder={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <br/>
                <TextField
                    fullWidth
                    id="subject"
                    name="subject"
                    label="Subject"
                    type="Text"
                    placeholder={formik.values.subject}
                    onChange={formik.handleChange}
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                />
                <br/>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="select"
                    value={selection}
                    onChange={handleChange}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                >
                    <MenuItem value={"dataset"}>General Dataset Information</MenuItem>
                    <MenuItem value={"download"}>Downloading the Dataset</MenuItem>
                    <MenuItem value={"devkit"}>Devkit Usage</MenuItem>
                    <MenuItem value={"benchmark"}>Benchmarks and Submissions</MenuItem>
                    <MenuItem value={"bugs"}>Bugs/Issues</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
                </FormControl>
                <br/>
                <TextField
                    fullWidth
                    multiline={true}
                    rows={5}
                    variant={"outlined"}
                    size={"medium"}
                    id="message"
                    name="message"
                    label="Message"
                    type="Text"
                    placeholder={""}
                    onChange={formik.handleChange}
                    error={formik.touched.message && Boolean(formik.errors.message)}
                    helperText={formik.touched.message && formik.errors.message}
                />
                <br/>
                <Button type="submit">
                    Submit
                </Button>
            </form>
            </div>
            </section>

    );
};

export default Contact;