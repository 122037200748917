import React from 'react';
import classNames from 'classnames';
import {SectionProps} from '../../utils/SectionProps';
import Video from '../../assets/video/website_video_f1.mp4';
import '../../assets/customStyles/styles.css';

const propTypes = {
    ...SectionProps.types
};

const defaultProps = {
    ...SectionProps.defaults
};

const Hero = ({
                  className,
                  topOuterDivider,
                  bottomOuterDivider,
                  topDivider,
                  bottomDivider,
                  hasBgColor,
                  invertColor,
                  ...props
              }) => {

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    return (
        <section
            {...props}
            className={outerClasses}
        >
            {/* Video */}
            <div className="edina_tm_universal_box_wrap">
                <div className="bg_wrap">
                    <div className="overlay_video">
                        <video src={Video} autoPlay='autoplay' muted='muted' loop='loop'>
                        </video>
                    </div>
                </div>
                <div className="content hero">
                    <div className="container hero"/>
                </div>
            </div>
        </section>
    );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero