import React from "react";
import { useDropzone } from "react-dropzone";
import "../assets/customStyles/styles.css";
import { CSVLink } from "react-csv";
import { useTable } from "react-table";

import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignOut,
  AmplifySignUp,
} from "@aws-amplify/ui-react";
import { API, Auth, graphqlOperation, Hub, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

import classNames from "classnames";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateUserMaster } from "../graphql/mutations";
// import {getUserMaster} from "../graphql/queries";

function App({ ...props }) {
  const [user, updateUser] = React.useState(null);
  const [file, updateFile] = React.useState([]);

  /*
   * AUTHENTICATION
   */
  // Amplify Sign-in/Sign-out
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => updateUser(user))
      .catch(() => console.log("No signed in user"));
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          console.log("signed in");
          return updateUser(data.payload.data);
        case "signOut":
          console.log("signed out");
          return updateUser(null);
      }
    });
  }, []);

  /*
   * UPLOAD
   */
  // Dropzone function executed after dropping
  // TODO: make the drop functionality better (bigger drop range)
  async function onDrop(acceptedFiles) {
    const file = acceptedFiles[0];
    try {
      await Storage.put(file.name, file, {
        level: "private",
        contentType: "application/zip", // contentType is optional
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }
  // Dropzone parameters
  const MAX_SIZE = 50000000; // 50 MB
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: MAX_SIZE,
    accept:
      "application/zip, application/x-zip-compressed, application/zip-compressed",
    class: "dropzone",
    multiple: false,
  });
  // TODO: Test
  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].size > MAX_SIZE;

  const outerClasses = classNames("hero section center-content");

  /*
   * USER SUBMISSIONS MANAGEMENT
   */
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [metadata, setMetadata] = React.useState(null);
  const [author, setAuthor] = React.useState(null);
  const [papertitle, setPaperTitle] = React.useState(null);
  const [paperurl, setPaperUrl] = React.useState(null);
  const [venue, setVenue] = React.useState(null);
  const [paperyear, setPaperYear] = React.useState(null);
  const [runtimeseconds, setRuntime] = React.useState(null);
  const [environment, setEnvironment] = React.useState(null);
  const [modelName, setModelName] = React.useState(null);
  const [fileID, setFileID] = React.useState(null);
  const [fileBench, setFileBench] = React.useState("");

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const csvLinkRef = React.useRef();

  const splitClasses = classNames("split-wrap");

  // Get model list from user's private S3 Folder
  const listFile = async () => {
    const result = await Storage.list("", { level: "private" });
    updateFile(result);
  };

  const deleteFile = async (fileKey) => {
    let ID = await getID(fileKey);
    let todelete = { id: ID };
    await API.graphql({
      query: mutations.deleteLidarOdModel,
      variables: { input: todelete },
    });
    await API.graphql({
      query: mutations.deleteRadarOdModel,
      variables: { input: todelete },
    });
    await API.graphql({
      query: mutations.deleteLidarDeModel,
      variables: { input: todelete },
    });
    await API.graphql({
      query: mutations.deleteRadarDeModel,
      variables: { input: todelete },
    });
    await API.graphql({
      query: mutations.deleteLidarLocModel,
      variables: { input: todelete },
    });
    await API.graphql({
      query: mutations.deleteRadarLocModel,
      variables: { input: todelete },
    });
    await Storage.remove(fileKey, { level: "private" });
    await listFile();
  };

  const getID = async (fileKey) => {
    let ID = (await Auth.currentUserCredentials()).identityId;
    const parts1 = ID.split(":");
    const parts = fileKey.split(".");
    return parts1[parts1.length - 1] + "-" + parts[0];
  };

  const getResult = async (ID) => {
    let results = [
      (
        await API.graphql({
          query: queries.getLidarOdModel,
          variables: { id: ID },
        })
      ).data.getLidarOdModel,
      (
        await API.graphql({
          query: queries.getRadarOdModel,
          variables: { id: ID },
        })
      ).data.getRadarOdModel,
      (
        await API.graphql({
          query: queries.getLidarDeModel,
          variables: { id: ID },
        })
      ).data.getLidarDeModel,
      (
        await API.graphql({
          query: queries.getRadarDeModel,
          variables: { id: ID },
        })
      ).data.getRadarDeModel,
      (
        await API.graphql({
          query: queries.getLidarLocModel,
          variables: { id: ID },
        })
      ).data.getLidarLocModel,
      (
        await API.graphql({
          query: queries.getRadarLocModel,
          variables: { id: ID },
        })
      ).data.getRadarLocModel,
    ];
    // console.log(results);
    for (let i = 0; i < 6; i++) {
      if (results[i] != null) {
        return results[i];
      }
    }
    // const results = [(await API.graphql({query: queries.getUserMaster, variables: {id: ID}})).data.getUserMaster];
    // return results[0];
  };

  // obtains model data from file key
  const getModelData = async (fileKey) => {
    let ID = await getID(fileKey);
    let result = await getResult(ID);
    if (result == null) return [null, null];
    else return [result, result.benchmark];
  };
  // copies model data onto public leaderboard database
  const publish = async (fileKey) => {
    let res = await getModelData(fileKey);
    let model = res[0];
    let bench = res[1];
    let twod = res[0].twod;
    delete model.createdAt;
    delete model.updatedAt;
    model.publish = !model.publish;
    if (bench == "odometry" && twod == false)
      await API.graphql(
        graphqlOperation(mutations.updateLidarOdModel, { input: model })
      );
    else if (bench == "odometry" && twod == true)
      await API.graphql(
        graphqlOperation(mutations.updateRadarOdModel, { input: model })
      );
    else if (bench == "detection" && twod == false)
      await API.graphql(
        graphqlOperation(mutations.updateLidarDeModel, { input: model })
      );
    else if (bench == "detection" && twod == true)
      await API.graphql(
        graphqlOperation(mutations.updateRadarDeModel, { input: model })
      );
    else if (bench == "localization" && twod == false)
      await API.graphql(
        graphqlOperation(mutations.updateLidarLocModel, { input: model })
      );
    else if (bench == "localization" && twod == true)
      await API.graphql(
        graphqlOperation(mutations.updateRadarLocModel, { input: model })
      );
  };
  // adds model data to submission page table
  const viewResults = async (fileKey) => {
    setFileID(fileKey);
    let res = await getModelData(fileKey);
    console.log(res);
    let model = res[0];
    if (model == null) return;
    let bench = res[1];
    let twod = res[0].twod;

    console.log(model);
    setFileBench(bench);
    setModelName(model.methodname);
    if (model.author == "default") {
      setMetadata("author");
      setAuthor("author");
    } else {
      setMetadata(model.author);
      setAuthor(model.author);
    }
    if (model.papertitle == "default") {
      setPaperTitle("papertitle");
    } else {
      setPaperTitle(model.papertitle);
    }
    if (model.venue == "default") {
      setVenue("venue");
    } else {
      setVenue(model.venue);
    }
    if (model.paperurl == "default") {
      setPaperUrl("paperurl");
    } else {
      setPaperUrl(model.paperurl);
    }
    setPaperYear(model.paperyear);
    setRuntime(model.runtimeseconds);
    setEnvironment(model.computer);

    let newData = [
      {
        col0: null,
        col1: null,
      },
    ];
    if (bench == "odometry") {
      //   newData.push({
      //     row0: model.methodname,
      //     row1: model.sensors,
      //     row2: model.trans,
      //     row3: model.rot,
      //     row4: model.runtimeseconds,
      //     row5: model.computer,
      //   });
      newData.push({
        col0: "Sensors",
        col1: model.sensors,
      });
      newData.push({
        col0: "Translation (%)",
        col1: model.trans,
      });
      newData.push({
        col0: "Rotation (deg/m)",
        col1: model.rot,
      });
    } else if (bench == "localization" && !twod) {
      //   newData = [
      //     {
      //       row0: null,
      //       row1: null,
      //       row2: null,
      //       row3: null,
      //       row4: null,
      //       row5: null,
      //       row6: null,
      //       row7: null,
      //       row8: null,
      //       row9: null,
      //     },
      //   ];
      //   newData.push({
      //     row0: model.methodname,
      //     row1: model.xrmse,
      //     row2: model.yrmse,
      //     row3: model.zrmse,
      //     row4: model.rollrmse,
      //     row5: model.pitchrmse,
      //     row6: model.yawrmse,
      //     row7: model.consist,
      //     row8: model.runtimeseconds,
      //     row9: model.computer,
      //   });
      newData.push({
        col0: "Reference Sensor",
        col1: model.ref_sensor,
      });
      newData.push({
        col0: "Test Sensor",
        col1: model.test_sensor,
      });
      newData.push({
        col0: "Lat RMSE (m)",
        col1: model.xrmse,
      });
      newData.push({
        col0: "Long RMSE (m)",
        col1: model.yrmse,
      });
      newData.push({
        col0: "Vert RMSE (m)",
        col1: model.zrmse,
      });
      newData.push({
        col0: "Roll RMSE (deg)",
        col1: model.rollrmse,
      });
      newData.push({
        col0: "Pitch RMSE (deg)",
        col1: model.pitchrmse,
      });
      newData.push({
        col0: "Yaw RMSE (deg)",
        col1: model.yawrmse,
      });
      newData.push({
        col0: "Consistency",
        col1: model.consist,
      });
    } else if (bench == "localization" && twod) {
      newData.push({
        col0: "Reference Sensor",
        col1: model.ref_sensor,
      });
      newData.push({
        col0: "Test Sensor",
        col1: model.test_sensor,
      });
      newData.push({
        col0: "Lat RMSE (m)",
        col1: model.xrmse,
      });
      newData.push({
        col0: "Long RMSE (m)",
        col1: model.yrmse,
      });
      newData.push({
        col0: "Yaw RMSE (deg)",
        col1: model.yawrmse,
      });
      newData.push({
        col0: "Consistency",
        col1: model.consist,
      });

      //   newData.push({
      //     row0: model.methodname,
      //     row1: model.xrmse,
      //     row2: model.yrmse,
      //     row3: model.yawrmse,
      //     row4: model.consist,
      //     row5: model.runtimeseconds,
      //     row6: model.computer,
      //   });
    } else if (bench == "detection") {
      //   newData = [
      //     {
      //       row0: null,
      //       row1: null,
      //       row2: null,
      //       row3: null,
      //       row4: null,
      //       row5: null,
      //     },
      //   ];

      newData.push({
        col0: "Car mAP",
        col1: model.carmAP,
      });
      newData.push({
        col0: "Pedestrian mAP",
        col1: model.pedmAP,
      });
      newData.push({
        col0: "Cyclist mAP",
        col1: model.cycmAP,
      });

      //   newData.push({
      //     row0: model.methodname,
      //     row1: model.carmAP,
      //     row2: model.pedmAP,
      //     row3: model.cycmAP,
      //     row4: model.runtimeseconds,
      //     row5: model.computer,
      //   });
    } else return null;

    let newColumns = [
      {
        Header: "Results",
        accessor: "col0",
      },
      {
        Header: "",
        accessor: "col1",
      },
    ];

    // if (bench == "odometry")
    //   newColumns.push(
    //     { Header: "Translation (%)", accessor: "col1" },
    //     { Header: "Rotation (deg/m)", accessor: "col2" },
    //     { Header: "Runtime (s)", accessor: "col3" },
    //     { Header: "Environment", accessor: "col4" }
    //   );
    // else if (bench == "localization" && twod == false)
    //   newColumns.push(
    //     { Header: "Long RMSE (m)", accessor: "col1" },
    //     { Header: "Lat RMSE (m)", accessor: "col2" },
    //     { Header: "Vert RMSE (m)", accessor: "col3" },
    //     { Header: "Roll RMSE (deg)", accessor: "col4" },
    //     { Header: "Pitch RMSE (deg)", accessor: "col5" },
    //     { Header: "Yaw RMSE (deg)", accessor: "col6" },
    //     { Header: "Consistency", accessor: "col7" },
    //     { Header: "Runtime (s)", accessor: "col8" },
    //     { Header: "Environment", accessor: "col9" }
    //   );
    // else if (bench == "localization" && twod == true)
    //   newColumns.push(
    //     { Header: "Long RMSE (m)", accessor: "col1" },
    //     { Header: "Lat RMSE (m)", accessor: "col2" },
    //     { Header: "Yaw RMSE (deg)", accessor: "col3" },
    //     { Header: "Consistency", accessor: "col4" },
    //     { Header: "Runtime (s)", accessor: "col5" },
    //     { Header: "Environment", accessor: "col6" }
    //   );
    // else if (bench == "detection")
    //   newColumns.push(
    //     { Header: "Car mAP", accessor: "col1" },
    //     { Header: "Pedestrian mAP", accessor: "col2" },
    //     { Header: "Cyclist mAP", accessor: "col3" },
    //     { Header: "Runtime (s)", accessor: "col4" },
    //     { Header: "Environment", accessor: "col5" }
    //   );
    // else return null;

    setColumns(newColumns);
    setData(newData);
  };
  const editSubmit = async (fileKey) => {
    let ID = await getID(fileKey);
    let res = await getResult(ID);
    let bench = res.benchmark;
    let twod = res.twod;
    res.author = author;
    res.methodname = modelName;
    res.papertitle = papertitle;
    res.venue = venue;
    res.paperurl = paperurl;
    res.paperyear = paperyear;
    res.runtimeseconds = runtimeseconds;
    res.computer = environment;
    delete res.createdAt;
    delete res.updatedAt;
    console.log(res);
    if (bench == "odometry" && twod == false)
      await API.graphql(
        graphqlOperation(mutations.updateLidarOdModel, { input: res })
      );
    else if (bench == "odometry" && twod == true)
      await API.graphql(
        graphqlOperation(mutations.updateRadarOdModel, { input: res })
      );
    else if (bench == "detection" && twod == false)
      await API.graphql(
        graphqlOperation(mutations.updateLidarDeModel, { input: res })
      );
    else if (bench == "detection" && twod == true)
      await API.graphql(
        graphqlOperation(mutations.updateRadarDeModel, { input: res })
      );
    else if (bench == "localization" && twod == false)
      await API.graphql(
        graphqlOperation(mutations.updateLidarLocModel, { input: res })
      );
    else if (bench == "localization" && twod == true)
      await API.graphql(
        graphqlOperation(mutations.updateRadarLocModel, { input: res })
      );
  };

  /*
   * CREATE EDIT METADATA/DISPLAY RESULTS COMPONENTS
   */

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        color: "#9CA9B3",
        marginTop: "20px",
        marginBottom: "20px",
        width: "40vw",
      },
      "& .MuiFormLabel-root": {
        color: "#9CA9B3",
        fontFamily: "Inter",
      },
      "& .MuiInputBase-root": {
        color: "#9CA9B3",
        fontFamily: "Inter",
      },
      "& .MuiFormHelperText-root": {
        color: "#9CA9B3",
        fontFamily: "Inter",
      },
      "& .MuiInput-underline::before": {
        borderBottom: "1px solid rgba(255, 255, 255, 0.7)",
      },
    },
  }));
  const classes = useStyles();
  const formTable = () => {
    const handleNameChange = (e) => {
      let v = String(e.target.value).slice(0, 30);
      if (v == null) return null;
      setModelName(v);
    };
    const handleMetaChange = (e) => {
      let v = String(e.target.value).slice(0, 100);
      setMetadata(v);
    };
    const handleAuthorChange = (e) => {
      let v = String(e.target.value).slice(0, 100);
      setAuthor(v);
    };
    const handlePaperTitleChange = (e) => {
      let v = String(e.target.value).slice(0, 150);
      setPaperTitle(v);
    };
    const handleVenueChange = (e) => {
      let v = String(e.target.value).slice(0, 10);
      setVenue(v);
    };
    const handlePaperYearChange = (e) => {
      let v = String(e.target.value);
      if (v.length != 4) return null;
      setPaperYear(e.target.value);
    };
    const handlePaperUrlChange = (e) => {
      let v = String(e.target.value).slice(0, 500);
      setPaperUrl(v);
    };
    const handleRuntimeChange = (e) => {
      let v = String(e.target.value).slice(0, 10);
      setRuntime(v);
    };
    const handleEnvironmentChange = (e) => {
      let v = String(e.target.value).slice(0, 50);
      setEnvironment(v);
    };
    if (
      fileID &&
      author != null &&
      modelName != null &&
      paperurl != null &&
      papertitle != null &&
      venue != null &&
      paperyear != null &&
      runtimeseconds != null &&
      environment != null
    ) {
      return (
        <div>
          <table className="min-w-full divide-y divide-gray-200">
            <tbody>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-body font-bold text-gray-900 uppercase tracking-wider"
                      {...column.getHeaderProps()}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>
            <tbody
              className="bg-white divide-y divide-gray-200"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="px-6 py-1 divide-y divide-gray-200 border-b whitespace-nowrap text-xs font-body text-gray-500"
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
              id="Method Name"
              label="Method Name"
              defaultValue={modelName}
              onChange={(e) => handleNameChange(e)}
              helperText="Edit Method Name"
            />
            <TextField
              id="Author"
              label="Author"
              defaultValue={author}
              onChange={(e) => handleAuthorChange(e)}
              helperText="Edit Author"
            />
            <TextField
              id="PaperTitle"
              label="PaperTitle"
              defaultValue={papertitle}
              onChange={(e) => handlePaperTitleChange(e)}
              helperText="Edit PaperTitle"
            />
            <TextField
              id="Year"
              label="Year"
              defaultValue={paperyear}
              onChange={(e) => handlePaperYearChange(e)}
              helperText="Edit Year"
            />
            <TextField
              id="Venue"
              label="Venue"
              defaultValue={venue}
              onChange={(e) => handleVenueChange(e)}
              helperText="Edit Venue"
            />
            <TextField
              id="PaperUrl"
              label="PaperUrl"
              defaultValue={paperurl}
              onChange={(e) => handlePaperUrlChange(e)}
              helperText="Edit PaperUrl"
            />
            <TextField
              id="Runtime (s)"
              label="Runtime (s)"
              defaultValue={runtimeseconds}
              onChange={(e) => handleRuntimeChange(e)}
              helperText="Edit Runtime"
            />
            <TextField
              id="Environment"
              label="Environment"
              defaultValue={environment}
              onChange={(e) => handleEnvironmentChange(e)}
              helperText="Edit Environment"
            />
          </form>
          <button
            className="button button-primary button-wide-mobile button-sm mb-32"
            onClick={() => {
              editSubmit(fileID);
            }}
          >
            Push Metadata Changes
          </button>
        </div>
      );
    }
  };

  // Download results as CSV
  const download = async (fileKey) => {
    // await viewResults(fileKey);
    // csvLinkRef?.current?.link.click();
    try {
      await Storage.get(file.name, file, {
        level: "private",
        contentType: "application/zip", // contentType is optional
      });
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  };

  // TODO: rename files if identical
  if (user) {
    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className="container center-items mx-auto px-8 lg:flex flex flex-col text-center content-center mt-5 dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive &&
                "Click or drop a zip file here to upload a submission"}
              {isDragActive && !isDragReject && "Please drop zip file here"}
              {isDragReject && "Please upload a valid zip file under 50 MB"}
              {isFileTooLarge && "File is too large: 50 MB maximum"}

              <ul className="list-group mt-2 text-green-600">
                {acceptedFiles.length > 0 &&
                  acceptedFiles.map((acceptedFile) => (
                    <li
                      className="list-group-item list-group-item-success"
                      key={acceptedFile.name}
                    >
                      Uploaded: {acceptedFile.name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <h1> Manage Submissions </h1>
          <h2>
            {" "}
            <a href="https://github.com/utiasASRL/pyboreas/tree/master/pyboreas/eval">
              Instructions
            </a>{" "}
          </h2>

          <button
            className="button button-primary button-wide-mobile button-sm mb-32"
            onClick={() => {
              listFile();
            }}
          >
            View Submissions
          </button>
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile">
                {file.map((file) => (
                  <div>
                    <ul className={"mb-16 custom_ul"}>
                      <li key={file.name}>
                        {file.key}
                        <p>Results:</p>
                        <button
                          className="button button-secondary button-wide-mobile button-sm"
                          onClick={() => {
                            viewResults(file.key);
                          }}
                        >
                          View / Edit Metadata
                        </button>
                        &nbsp;
                        <button
                          className="button button-secondary button-wide-mobile button-sm"
                          onClick={() => {
                            publish(file.key);
                          }}
                        >
                          Publish / Unpublish
                        </button>
                        &nbsp;
                        <button
                          className="button button-warning button-wide-mobile button-sm"
                          onClick={() => {
                            deleteFile(file.key);
                          }}
                        >
                          Delete
                        </button>
                        <br />
                        <br />
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
              <div className="split-item-content center-content-mobile">
                {formTable()}
                <CSVLink
                  data={data}
                  className="exportButton"
                  filename="file.csv"
                  ref={csvLinkRef}
                ></CSVLink>
              </div>
            </div>
          </div>

          <div className="w-2 mt-10 content-center mx-auto center-items lg:flex flex">
            <AmplifySignOut className={"amplify-sign-out"} />
          </div>
        </div>
      </section>
    );
  }
  // Signed-out state render
  return (
    <section {...props} className={outerClasses}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AmplifyAuthenticator
          style={{ "--min-width": "10rem" }}
          usernameAlias="email"
        >
          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: "email",
              },
              {
                type: "password",
              },
            ]}
          />
          <AmplifySignIn slot="sign-in" usernameAlias="email" />
        </AmplifyAuthenticator>
      </div>
    </section>
  );
}

export default App;
