/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRadarOdModel = /* GraphQL */ `
  query GetRadarOdModel($id: ID!) {
    getRadarOdModel(id: $id) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const listRadarOdModels = /* GraphQL */ `
  query ListRadarOdModels(
    $filter: ModelRadarOdModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRadarOdModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLidarOdModel = /* GraphQL */ `
  query GetLidarOdModel($id: ID!) {
    getLidarOdModel(id: $id) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const listLidarOdModels = /* GraphQL */ `
  query ListLidarOdModels(
    $filter: ModelLidarOdModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLidarOdModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRadarDeModel = /* GraphQL */ `
  query GetRadarDeModel($id: ID!) {
    getRadarDeModel(id: $id) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const listRadarDeModels = /* GraphQL */ `
  query ListRadarDeModels(
    $filter: ModelRadarDeModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRadarDeModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        runtimeseconds
        computer
        twod
        venue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLidarDeModel = /* GraphQL */ `
  query GetLidarDeModel($id: ID!) {
    getLidarDeModel(id: $id) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const listLidarDeModels = /* GraphQL */ `
  query ListLidarDeModels(
    $filter: ModelLidarDeModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLidarDeModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        runtimeseconds
        computer
        twod
        venue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRadarTrModel = /* GraphQL */ `
  query GetRadarTrModel($id: ID!) {
    getRadarTrModel(id: $id) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const listRadarTrModels = /* GraphQL */ `
  query ListRadarTrModels(
    $filter: ModelRadarTrModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRadarTrModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLidarTrModel = /* GraphQL */ `
  query GetLidarTrModel($id: ID!) {
    getLidarTrModel(id: $id) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const listLidarTrModels = /* GraphQL */ `
  query ListLidarTrModels(
    $filter: ModelLidarTrModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLidarTrModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRadarLocModel = /* GraphQL */ `
  query GetRadarLocModel($id: ID!) {
    getRadarLocModel(id: $id) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      yawrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const listRadarLocModels = /* GraphQL */ `
  query ListRadarLocModels(
    $filter: ModelRadarLocModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRadarLocModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        yawrmse
        consist
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLidarLocModel = /* GraphQL */ `
  query GetLidarLocModel($id: ID!) {
    getLidarLocModel(id: $id) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      zrmse
      yawrmse
      pitchrmse
      rollrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const listLidarLocModels = /* GraphQL */ `
  query ListLidarLocModels(
    $filter: ModelLidarLocModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLidarLocModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        zrmse
        yawrmse
        pitchrmse
        rollrmse
        consist
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMaster = /* GraphQL */ `
  query GetUserMaster($id: ID!) {
    getUserMaster(id: $id) {
      id
      RadarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      LidarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      RadarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      LidarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      RadarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      LidarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      RadarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        yawrmse
        consist
      }
      LidarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        zrmse
        rollrmse
        pitchrmse
        yawrmse
        consist
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserMasters = /* GraphQL */ `
  query ListUserMasters(
    $filter: ModelUserMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
