// icons (currently unused)
import {WiDayCloudy, WiDaySunny, WiNightClear, WiNightSnowThunderstorm, WiSunset} from "react-icons/wi";
import {BiCloudSnow, BiCloudRain} from "react-icons/bi";

// list of sequences with title, tags, and youtube id
const sequences = [
    {
        title: "2020-11-26-13-58",
        tag:["Overcast", "Snow"],
        icon: <WiDayCloudy/>,
        src:"7IrqynUoCoc",
    },
    {
        title:"2020-12-01-13-26",
        tag:["Overcast", "Snow", "Snowing"],
        icon: <BiCloudSnow/>,
        src:"4FXpYurwEBI"},
    {
        title: "2020-12-04-14-00",
        tag:["Overcast", "Snow"],
        icon: <WiDayCloudy/>,
        src:"2F-Mc0TZV7w"},
    {
        title: "2020-12-18-13-44",
        tag: ["Sun", "Snow"],
        icon: <WiDaySunny/>,
        src: "dxtUnSWLinE"},
    {
        title: "2021-01-15-12-17",
        tag: ["Sun", "Clouds", "Snow"],
        icon: <WiDayCloudy/>,
        src: "KBdz9Uz4XuE"},
    {
        title: "2021-01-19-15-08",
        tag: ["Clouds", "Snow"],
        icon: <WiDayCloudy/>,
        src: "_LLDeb7Vo6g"},
    {
        title: "2021-01-26-10-59",
        tag: ["Overcast", "Snow", "Snowing"],
        icon: <WiNightSnowThunderstorm/>,
        src: "sL9UtHcR66o"},
    {
        title: "2021-01-26-11-22",
        tag: ["Overcast", "Snow", "Snowing"],
        icon: <WiNightSnowThunderstorm/>,
        src: "Y6KtHjzaiWU"},
    {
        title: "2021-02-02-14-07",
        tag: ["Overcast", "Snow"],
        icon: <WiDayCloudy/>,
        src: "fu9p3EcRKDE"},
    {
        title: "2021-02-09-12-55",
        tag: ["Sun", "Clouds", "Snow"],
        icon: <WiDaySunny/>,
        src: "fLuhsCsjHB8"},
    {
        title: "2021-03-02-13-38",
        tag: ["Sun", "Clouds", "Snow"],
        icon: <WiDaySunny/>,
        src: "woXfJ27JhoY"},
    {
        title: "2021-03-09-14-23",
        tag: ["Sun"],
        icon: <WiDaySunny/>,
        src: "gR22J5Vbz8o"},
    {
        title: "2021-03-23-12-43",
        tag: ["Overcast", "Construction"],
        icon: <WiDayCloudy/>,
        src: "SlCzeJN0cOQ"},
    {
        title: "2021-03-30-14-23",
        tag: ["Sun", "Clouds", "Construction"],
        icon: <WiDaySunny/>,
        src: "Kij-s1BtzVo"},
    {
        title: "2021-04-08-12-44",
        tag: ["Sun"],
        icon: <WiDaySunny/>,
        src: "Ck625sTqoLs"},
    {
        title: "2021-04-13-14-49",
        tag: ["Sun", "Clouds", "Construction"],
        icon: <WiDaySunny/>,
        src: "f5JtmXK-scY"},
    {
        title: "2021-04-15-18-55",
        tag: ["Clouds", "Construction"],
        icon: <WiDaySunny/>,
        src: "f5C1fLPer5s"},
    {
        title: "2021-04-20-14-11",
        tag: ["Clouds", "Construction"],
        icon: <WiDaySunny/>,
        src: "zctZUY0aKwY"},
    {
        title: "2021-04-22-15-00",
        tag: ["Clouds", "Snowing", "Construction"],
        icon: <WiDaySunny/>,
        src: "_krRMDhDo34"},
    {
        title: "2021-04-29-15-55",
        tag: ["Overcast", "Rain"],
        icon: <BiCloudRain/>,
        src: "ieZAQTo2JsI"},
    {
        title: "2021-05-06-13-19",
        tag: ["Sun", "Clouds"],
        icon: <WiDaySunny/>,
        src: "rQgrQ9dZut8"},
    {
        title: "2021-05-13-16-11",
        tag: ["Sun", "Clouds"],
        icon: <WiDaySunny/>,
        src: "VbpaKnO0nj0"},
    {
        title: "2021-06-03-16-00",
        tag: ["Sun", "Clouds"],
        icon: <WiDaySunny/>,
        src: "VT62XMgbuA8"},
    {
        title: "2021-06-17-17-52",
        tag: ["Sun"],
        icon: <WiDaySunny/>,
        src: "4W0-ovO5MBw"},
    {
        title: "2021-06-29-18-53",
        tag: ["Overcast", "Rain"],
        icon: <BiCloudRain/>,
        src: "KUbQ87UhLt4"},
    {
        title: "2021-06-29-20-43",
        tag: ["Sun", "Clouds", "Dusk"],
        icon: <WiDaySunny/>,
        src: "EbBcYlaZPdw"},
    {
        title: "2021-07-20-17-33",
        tag: ["Clouds", "Rain"],
        icon: <BiCloudRain/>,
        src: "s3iVDuKB-Ds"},
    {
        title: "2021-07-27-14-43",
        tag: ["Clouds"],
        icon: <WiDaySunny/>,
        src: "hTVmjpwRLXc"},
    {
        title: "2021-08-05-13-34",
        tag: ["Sun", "Clouds"],
        icon: <WiDaySunny/>,
        src: "XmwZyD-5sU0"},
    {
        title: "2021-09-02-11-42",
        tag: ["Sun"],
        icon: <WiDaySunny/>,
        src: "Siu5gNOYqt4"},
    {
        title: "2021-09-07-09-35",
        tag: ["Sun"],
        icon: <WiDaySunny/>,
        src: "KAe1gzTLm20"},
    {
        title: "2021-09-08-21-00",
        tag: ["Night"],
        icon: <WiNightClear/>,
        src: "wWClcbn1hMk"},
    {
        title: "2021-09-09-15-28",
        tag: ["Sun", "Clouds", "Alternate", "Construction"],
        icon: <WiDaySunny/>,
        src: "ldNI4Qe8lIs"},
    {
        title: "2021-09-14-20-00",
        tag: ["Night"],
        icon: <WiNightClear/>,
        src: "oyCVhbsavGA"},
    {
        title: "2021-10-05-15-35",
        tag: ["Overcast"],
        icon: <WiDayCloudy/>,
        src: "8qn1kdqq4as"},
    {
        title: "2021-10-15-12-35",
        tag: ["Clouds"],
        icon: <WiDayCloudy/>,
        src: "orqdXxMv5FE"},
    {
        title: "2021-10-22-11-36",
        tag: ["Clouds"],
        icon: <WiNightClear/>,
        src: "1_4kBl7rd9M"},
    {
        title: "2021-10-26-12-35",
        tag: ["Overcast", "Rain"],
        icon: <BiCloudRain/>,
        src: "69SQKE7YoRg"},
    {
        title: "2021-11-02-11-16",
        tag: ["Sun", "Clouds"],
        icon: <WiDayCloudy/>,
        src: "3QxBEmAkuPc"},
    {
        title: "2021-11-06-18-55",
        tag: ["Night"],
        icon: <WiNightClear/>,
        src: "U8SpZVKlGqM"},
    {
        title: "2021-11-14-09-47",
        tag: ["Overcast"],
        icon: <WiDayCloudy/>,
        src: "CkZ8uzXGcHw"},
    {
        title: "2021-11-16-14-10",
        tag: ["Clouds"],
        icon: <WiNightClear/>,
        src: "-eI4JAzNN-0"},
    {
        title: "2021-11-23-14-27",
        tag: ["Sun", "Clouds"],
        icon: <WiNightClear/>,
        src: "kg6pouR8hAk"},
    {
        title: "2021-11-28-09-18",
        tag: ["Overcast", "Snow", "Snowing"],
        icon: <WiNightClear/>,
        src: "RED7X5hYb6s"},
];

export default sequences