import React from 'react';
import classNames from 'classnames';
import Contact from "../Contact";
import {Link} from "react-router-dom";

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
        </li>
        {/*<li>*/}
        {/*  <a href="boreas@robotics.utias.utoronto.ca">Contact: boreas@robotics.utias.utoronto.ca</a>*/}
        {/*</li>*/}
      </ul>
    </nav>
  );
}

export default FooterNav;