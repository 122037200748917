/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRadarOdModel = /* GraphQL */ `
  mutation CreateRadarOdModel(
    $input: CreateRadarOdModelInput!
    $condition: ModelRadarOdModelConditionInput
  ) {
    createRadarOdModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const updateRadarOdModel = /* GraphQL */ `
  mutation UpdateRadarOdModel(
    $input: UpdateRadarOdModelInput!
    $condition: ModelRadarOdModelConditionInput
  ) {
    updateRadarOdModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const deleteRadarOdModel = /* GraphQL */ `
  mutation DeleteRadarOdModel(
    $input: DeleteRadarOdModelInput!
    $condition: ModelRadarOdModelConditionInput
  ) {
    deleteRadarOdModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const createLidarOdModel = /* GraphQL */ `
  mutation CreateLidarOdModel(
    $input: CreateLidarOdModelInput!
    $condition: ModelLidarOdModelConditionInput
  ) {
    createLidarOdModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const updateLidarOdModel = /* GraphQL */ `
  mutation UpdateLidarOdModel(
    $input: UpdateLidarOdModelInput!
    $condition: ModelLidarOdModelConditionInput
  ) {
    updateLidarOdModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const deleteLidarOdModel = /* GraphQL */ `
  mutation DeleteLidarOdModel(
    $input: DeleteLidarOdModelInput!
    $condition: ModelLidarOdModelConditionInput
  ) {
    deleteLidarOdModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      rot
      runtimeseconds
      computer
      trans
      twod
      venue
      sensors
      createdAt
      updatedAt
    }
  }
`;
export const createRadarDeModel = /* GraphQL */ `
  mutation CreateRadarDeModel(
    $input: CreateRadarDeModelInput!
    $condition: ModelRadarDeModelConditionInput
  ) {
    createRadarDeModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const updateRadarDeModel = /* GraphQL */ `
  mutation UpdateRadarDeModel(
    $input: UpdateRadarDeModelInput!
    $condition: ModelRadarDeModelConditionInput
  ) {
    updateRadarDeModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const deleteRadarDeModel = /* GraphQL */ `
  mutation DeleteRadarDeModel(
    $input: DeleteRadarDeModelInput!
    $condition: ModelRadarDeModelConditionInput
  ) {
    deleteRadarDeModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const createLidarDeModel = /* GraphQL */ `
  mutation CreateLidarDeModel(
    $input: CreateLidarDeModelInput!
    $condition: ModelLidarDeModelConditionInput
  ) {
    createLidarDeModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const updateLidarDeModel = /* GraphQL */ `
  mutation UpdateLidarDeModel(
    $input: UpdateLidarDeModelInput!
    $condition: ModelLidarDeModelConditionInput
  ) {
    updateLidarDeModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const deleteLidarDeModel = /* GraphQL */ `
  mutation DeleteLidarDeModel(
    $input: DeleteLidarDeModelInput!
    $condition: ModelLidarDeModelConditionInput
  ) {
    deleteLidarDeModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      carmAP
      cycmAP
      methodname
      papertitle
      paperurl
      paperyear
      pedmAP
      runtimeseconds
      computer
      twod
      venue
      createdAt
      updatedAt
    }
  }
`;
export const createRadarTrModel = /* GraphQL */ `
  mutation CreateRadarTrModel(
    $input: CreateRadarTrModelInput!
    $condition: ModelRadarTrModelConditionInput
  ) {
    createRadarTrModel(input: $input, condition: $condition) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const updateRadarTrModel = /* GraphQL */ `
  mutation UpdateRadarTrModel(
    $input: UpdateRadarTrModelInput!
    $condition: ModelRadarTrModelConditionInput
  ) {
    updateRadarTrModel(input: $input, condition: $condition) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const deleteRadarTrModel = /* GraphQL */ `
  mutation DeleteRadarTrModel(
    $input: DeleteRadarTrModelInput!
    $condition: ModelRadarTrModelConditionInput
  ) {
    deleteRadarTrModel(input: $input, condition: $condition) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const createLidarTrModel = /* GraphQL */ `
  mutation CreateLidarTrModel(
    $input: CreateLidarTrModelInput!
    $condition: ModelLidarTrModelConditionInput
  ) {
    createLidarTrModel(input: $input, condition: $condition) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const updateLidarTrModel = /* GraphQL */ `
  mutation UpdateLidarTrModel(
    $input: UpdateLidarTrModelInput!
    $condition: ModelLidarTrModelConditionInput
  ) {
    updateLidarTrModel(input: $input, condition: $condition) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const deleteLidarTrModel = /* GraphQL */ `
  mutation DeleteLidarTrModel(
    $input: DeleteLidarTrModelInput!
    $condition: ModelLidarTrModelConditionInput
  ) {
    deleteLidarTrModel(input: $input, condition: $condition) {
      id
      methodname
      benchmark
      publish
      bench1
      bench2
      author
      createdAt
      updatedAt
    }
  }
`;
export const createRadarLocModel = /* GraphQL */ `
  mutation CreateRadarLocModel(
    $input: CreateRadarLocModelInput!
    $condition: ModelRadarLocModelConditionInput
  ) {
    createRadarLocModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      yawrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const updateRadarLocModel = /* GraphQL */ `
  mutation UpdateRadarLocModel(
    $input: UpdateRadarLocModelInput!
    $condition: ModelRadarLocModelConditionInput
  ) {
    updateRadarLocModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      yawrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const deleteRadarLocModel = /* GraphQL */ `
  mutation DeleteRadarLocModel(
    $input: DeleteRadarLocModelInput!
    $condition: ModelRadarLocModelConditionInput
  ) {
    deleteRadarLocModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      yawrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const createLidarLocModel = /* GraphQL */ `
  mutation CreateLidarLocModel(
    $input: CreateLidarLocModelInput!
    $condition: ModelLidarLocModelConditionInput
  ) {
    createLidarLocModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      zrmse
      yawrmse
      pitchrmse
      rollrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const updateLidarLocModel = /* GraphQL */ `
  mutation UpdateLidarLocModel(
    $input: UpdateLidarLocModelInput!
    $condition: ModelLidarLocModelConditionInput
  ) {
    updateLidarLocModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      zrmse
      yawrmse
      pitchrmse
      rollrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const deleteLidarLocModel = /* GraphQL */ `
  mutation DeleteLidarLocModel(
    $input: DeleteLidarLocModelInput!
    $condition: ModelLidarLocModelConditionInput
  ) {
    deleteLidarLocModel(input: $input, condition: $condition) {
      id
      author
      benchmark
      publish
      methodname
      papertitle
      paperurl
      paperyear
      runtimeseconds
      computer
      twod
      venue
      ref_sensor
      test_sensor
      xrmse
      yrmse
      zrmse
      yawrmse
      pitchrmse
      rollrmse
      consist
      createdAt
      updatedAt
    }
  }
`;
export const createUserMaster = /* GraphQL */ `
  mutation CreateUserMaster(
    $input: CreateUserMasterInput!
    $condition: ModelUserMasterConditionInput
  ) {
    createUserMaster(input: $input, condition: $condition) {
      id
      RadarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      LidarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      RadarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      LidarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      RadarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      LidarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      RadarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        yawrmse
        consist
      }
      LidarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        zrmse
        rollrmse
        pitchrmse
        yawrmse
        consist
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserMaster = /* GraphQL */ `
  mutation UpdateUserMaster(
    $input: UpdateUserMasterInput!
    $condition: ModelUserMasterConditionInput
  ) {
    updateUserMaster(input: $input, condition: $condition) {
      id
      RadarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      LidarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      RadarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      LidarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      RadarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      LidarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      RadarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        yawrmse
        consist
      }
      LidarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        zrmse
        rollrmse
        pitchrmse
        yawrmse
        consist
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserMaster = /* GraphQL */ `
  mutation DeleteUserMaster(
    $input: DeleteUserMasterInput!
    $condition: ModelUserMasterConditionInput
  ) {
    deleteUserMaster(input: $input, condition: $condition) {
      id
      RadarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      LidarOd {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        rot
        runtimeseconds
        computer
        trans
        twod
        venue
        sensors
      }
      RadarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      LidarDe {
        id
        author
        benchmark
        publish
        carmAP
        cycmAP
        methodname
        papertitle
        paperurl
        paperyear
        pedmAP
        rot
        runtimeseconds
        computer
        twod
        venue
      }
      RadarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      LidarTr {
        id
        methodname
        benchmark
        publish
        bench1
        bench2
        author
      }
      RadarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        yawrmse
        consist
      }
      LidarLoc {
        id
        author
        benchmark
        publish
        methodname
        papertitle
        paperurl
        paperyear
        runtimeseconds
        computer
        twod
        venue
        ref_sensor
        test_sensor
        xrmse
        yrmse
        zrmse
        rollrmse
        pitchrmse
        yawrmse
        consist
      }
      createdAt
      updatedAt
    }
  }
`;
