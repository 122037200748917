import React, { useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import Expand from "react-expand-animated";

import boreas from "../../assets/images/boreas-logo-banner.svg";
import UofT from "../../assets/images/toronto.svg";

import "../../assets/customStyles/vehicleHover.css";
import Map from "../elements/Map";
import "leaflet/dist/leaflet.css";

import SensorSuite from "../elements/SensorSuite";

import "../../assets/customStyles/styles.css";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertMobile,
  invertDesktop,
  invertColor,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",

    className
  );

  const splitClasses = classNames("split-wrap", alignTop && "align-top");

  const [hopen, sethOpen] = useState([false]);
  const transitions = ["height", "opacity", "background"];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={splitClasses}>
          <div
            className="split-item"
            style={{ width: "100%", display: "flex" }}
          >
            <div
              className="split-item-content center-content-mobile reveal-from-right"
              data-reveal-container=".split-item"
              style={{ display: "inline-flex", justifyContent: "center" }}
            >
              <div
                className="mt-16 mb-16 reveal-from-bottom"
                data-reveal-delay="200"
              >
                <img
                  src={boreas}
                  style={{ width: "40em", marginTop: -50, marginBottom: -100 }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="split-item-content center-content-mobile reveal-from-left"
              data-reveal-container=".split-item"
              style={{ display: "inline-flex", justifyContent: "center" }}
            >
              <div
                className="mb-16 mt-32 reveal-from-bottom center"
                data-reveal-delay="400"
              >
                <img
                  src={UofT}
                  style={{ width: "17em", marginBottom: -20 }}
                  alt=""
                />
              </div>
            </div>
          </div>
          <h2
            className="m-0 mb-32 reveal-from-bottom center-content"
            data-reveal-delay="400"
          >
            A Multi-Season Autonomous Driving Dataset
          </h2>
          <div
            className="reveal-from-bottom center center-content"
            data-reveal-delay="600"
          >
            <Link
              to="/download"
              className="button button-primary button-wide-mobile button-sm"
              style={{
                zIndex: 10,
                width: 250,
                height: 80,
                alignItems: "center",
                fontSize: 26,
              }}
            >
              Download
            </Link>
          </div>
          <div
            className="reveal-from-bottom center center-content mt-32"
            data-reveal-delay="600"
          >
            <p>
              The Boreas dataset was collected by driving a repeated route over
              the course of 1 year resulting in stark seasonal variations. In
              total, Boreas contains over 350km of driving data including
              several sequences with adverse weather conditions such as rain and
              heavy snow. The Boreas data-taking platform features a unique
              high-quality sensor suite with a 128-channel Velodyne Alpha Prime
              lidar, a 360-degree Navtech radar, and accurate ground truth poses
              obtained from an Applanix POSLV GPS/IMU. We currently support live
              and open benchmarks for odometry, metric localization, and 3D
              object detection.
            </p>
          </div>
          <div className="split-item">
            <div
              className="split-item-content center-content-mobile reveal-from-right"
              data-reveal-container=".split-item"
              style={{ width: "578px", alignSelf: "center" }}
            >
              <SensorSuite />
            </div>
            <div
              className="split-item-content center-content-mobile reveal-from-left"
              data-reveal-container=".split-item"
              style={{ width: "578px", alignSelf: "center" }}
            >
              <Map />
            </div>
          </div>

          <div className="reveal-from-bottom container">
            <p>
              <p>
                {" "}
                If you use our dataset in your research, please cite our paper:{" "}
              </p>
              <b>Boreas: A Multi-Season Autonomous Driving Dataset</b>
              <br />
              Keenan Burnett, David J. Yoon, Yuchen Wu, Andrew Zou Li, Haowei
              Zhang, Shichen Lu, Jingxing Qian, Wei-Kang Tseng, Andrew Lambert,
              Keith Y.K. Leung, Angela P. Schoellig, Timothy D. Barfoot
              <br />
              <i>International Journal of Robotics Research (IJRR) 2023</i>
              <br />
              <a href={"https://arxiv.org/abs/2203.10168"}>[paper]</a> &nbsp;
              <a href={"https://youtu.be/Cay6rSzeo1E"}>[video]</a> &nbsp;
              <a
                onClick={() => sethOpen([!hopen[0]])}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                [bibtex]
              </a>{" "}
              &nbsp;
              <a href={"https://github.com/utiasASRL/pyboreas"}>[code]</a>{" "}
              &nbsp;
              <Expand open={hopen[0]} duration={300} transitions={transitions}>
                <div
                  style={{
                    height: 300,
                    background: "#212b35",
                    color: "#ffffff",
                  }}
                >
                  <pre>
                    {`@article{burnett_ijrr23,
                        author = {Keenan Burnett and David J Yoon and Yuchen Wu and Andrew Z Li and Haowei Zhang and Shichen Lu and Jingxing Qian and Wei-Kang Tseng and Andrew Lambert and Keith YK Leung and Angela P Schoellig and Timothy D Barfoot},
                        title ={Boreas: A multi-season autonomous driving dataset},
                        journal = {The International Journal of Robotics Research},
                        volume = {42},
                        number = {1-2},
                        pages = {33-42},
                        year = {2023},
                        doi = {10.1177/02783649231160195},
                        abstract = { The Boreas dataset was collected by driving a repeated route over the course of 1 year, resulting in stark seasonal variations and adverse weather conditions such as rain and falling snow. In total, the Boreas dataset includes over 350 km of driving data featuring a 128-channel Velodyne Alpha-Prime lidar, a 360° Navtech CIR304-H scanning radar, a 5MP FLIR Blackfly S camera, and centimetre-accurate post-processed ground truth poses. Our dataset will support live leaderboards for odometry, metric localization, and 3D object detection. The dataset and development kit are available at boreas.utias.utoronto.ca. }
                        }`}
                  </pre>
                </div>
              </Expand>
            </p>
          </div>

          <p style={{ fontSize: 12 }}>
            This work is licensed under a{" "}
            <a href={"https://creativecommons.org/licenses/by/4.0/"}>
              Creative Commons Attribution 4.0 International Public License (“CC
              BY 4.0”)
            </a>{" "}
            .
          </p>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
