import React, { useEffect } from "react";
import classNames from "classnames";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { useTable, useSortBy } from "react-table";
import { Spinner } from "react-spinners-css";

import { useAPI } from "../apiContext";

// Template Props
function App({ ...props }) {
  const outerClasses = classNames("hero section center-content");

  // States
  const { allModels, isLoading } = useAPI();
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [value, setValue] = React.useState(0);

  // Load on render
  useEffect(() => {
    if (!isLoading) {
      let newData = [
        {
          col0: null,
          col1: null,
          col2: null,
          col3: null,
          col4: null,
          col5: null,
          col6: null,
          col7: null,
          col8: null,
          col9: null,
          col10: null,
          col11: null,
          col12: null,
        },
      ];

      // Initialize table with default (first benchmark -> Radar Localization)
      for (let i = 0; i < allModels[0].length; i += 1) {
        let model = allModels[0][i];
        if (model.publish == false) continue;
        newData.push({
          col0: model.methodname,
          col1: model.ref_sensor,
          col2: model.test_sensor,
          col3: model.xrmse,
          col4: model.yrmse,
          col5: model.yawrmse,
          col6: model.consist,
          col7: model.runtimeseconds,
          col8: model.computer,
          col9: model.author,
          col10: (
            <b>
              {" "}
              <a href={model.paperurl}>{model.papertitle}</a>
            </b>
          ),
          col11: model.venue,
          col12: model.paperyear,
        });
      }

      let newColumns = [
        {
          Header: "Method",
          accessor: "col0",
        },
        {
          Header: "Ref Sensor",
          accessor: "col1",
        },
        {
          Header: "Test Sensor",
          accessor: "col2",
        },
        {
          Header: "Lat RMSE (m)",
          accessor: "col3",
        },
        {
          Header: "Long RMSE (m)",
          accessor: "col4",
        },
        {
          Header: "Yaw RMSE (deg)",
          accessor: "col5",
        },
        {
          Header: "Consistency",
          accessor: "col6",
        },
        {
          Header: "Runtime (s)",
          accessor: "col7",
        },
        {
          Header: "Environment",
          accessor: "col8",
        },
        {
          Header: "Authors",
          accessor: "col9",
        },
        {
          Header: "Paper Title",
          accessor: "col10",
        },
        {
          Header: "Venue",
          accessor: "col11",
        },
        {
          Header: "Year",
          accessor: "col12",
        },
      ];
      setColumns(newColumns);
      setData(newData);
    }
  }, [isLoading]);

  // Update States
  const handleChange = (event, newValue) => {
    if (newValue < 0 || newValue > 7) return;

    let newData = [
      {
        col0: null,
        col1: null,
        col2: null,
        col3: null,
        col4: null,
        col5: null,
        col6: null,
        col7: null,
        col8: null,
        col9: null,
      },
    ];
    if (newValue == 0) {
      newData = [
        {
          col0: null,
          col1: null,
          col2: null,
          col3: null,
          col4: null,
          col5: null,
          col6: null,
          col7: null,
          col8: null,
          col9: null,
          col10: null,
          col11: null,
          col12: null,
        },
      ];
    } else if (newValue == 2) {
      newData = [
        {
          col0: null,
          col1: null,
          col2: null,
          col3: null,
          col4: null,
          col5: null,
          col6: null,
          col7: null,
          col8: null,
          col9: null,
          col10: null,
          col11: null,
          col12: null,
          col13: null,
          col14: null,
          col15: null,
        },
      ];
    } else if (newValue == 4 || newValue == 5) {
      newData = [
        {
          col0: null,
          col1: null,
          col2: null,
          col3: null,
          col4: null,
          col5: null,
          col6: null,
          col7: null,
          col8: null,
          col9: null,
        },
      ];
    }
    // I push the actual benchmark values followed by a blank row with just author info
    // Doesn't appear to be a better way of doing this
    for (let i = 0; i < allModels[newValue].length * 2; i += 2) {
      // todo: case statement here.
      let model = allModels[newValue][i / 2];
      if (model.publish == false) continue;
      if (newValue == 0) {
        // 2d localization
        newData.push({
          col0: model.methodname,
          col1: model.ref_sensor,
          col2: model.test_sensor,
          col3: model.xrmse,
          col4: model.yrmse,
          col5: model.yawrmse,
          col6: model.consist,
          col7: model.runtimeseconds,
          col8: model.computer,
          col9: model.author,
          col10: (
            <b>
              {" "}
              <a href={model.paperurl}>{model.papertitle}</a>
            </b>
          ),
          col11: model.venue,
          col12: model.paperyear,
        });
      } else if (newValue == 1 || newValue == 3) {
        // odometry
        newData.push({
          col0: model.methodname,
          col1: model.sensors,
          col2: model.trans,
          col3: model.rot,
          col4: model.runtimeseconds,
          col5: model.computer,
          col6: model.author,
          col7: (
            <b>
              {" "}
              <a href={model.paperurl}>{model.papertitle}</a>
            </b>
          ),
          col8: model.venue,
          col9: model.paperyear,
        });
      } else if (newValue == 2) {
        // 3d localization
        newData.push({
          col0: model.methodname,
          col1: model.ref_sensor,
          col2: model.test_sensor,
          col3: model.xrmse,
          col4: model.yrmse,
          col5: model.zrmse,
          col6: model.rollrmse,
          col7: model.pitchrmse,
          col8: model.yawrmse,
          col9: model.consist,
          col10: model.runtimeseconds,
          col11: model.computer,
          col12: model.author,
          col13: (
            <b>
              {" "}
              <a href={model.paperurl}>{model.papertitle}</a>
            </b>
          ),
          col14: model.venue,
          col15: model.paperyear,
        });
      } else if (newValue == 4 || newValue == 5) {
        // detection
        newData.push({
          col0: model.methodname,
          col1: model.carmAP,
          col2: model.pedmAP,
          col3: model.cycmAP,
          col4: model.runtimeseconds,
          col5: model.computer,
          col6: model.author,
          col7: (
            <b>
              {" "}
              <a href={model.paperurl}>{model.papertitle}</a>
            </b>
          ),
          col8: model.venue,
          col9: model.paperyear,
        });
      } else {
        continue;
      }
    }

    let newColumns = [
      {
        Header: "Method",
        accessor: "col0",
      },
    ];

    // Add column names manually: benchmark metrics (to be changed)
    if (newValue == 1 || newValue == 3) {
      // odometry
      newColumns.push(
        { Header: "Sensors", accessor: "col1" },
        { Header: "Translation (%)", accessor: "col2" },
        { Header: "Rotation (deg/m)", accessor: "col3" },
        { Header: "Runtime (s)", accessor: "col4" },
        { Header: "Environment", accessor: "col5" },
        { Header: "Authors", accessor: "col6" },
        { Header: "Paper Title", accessor: "col7" },
        { Header: "Venue", accessor: "col8" },
        { Header: "Year", accessor: "col9" }
      );
    } else if (newValue == 2) {
      // 3d localization
      newColumns.push(
        { Header: "Ref Sensor", accessor: "col1" },
        { Header: "Test Sensor", accessor: "col2" },
        { Header: "Lat RMSE (m)", accessor: "col3" },
        { Header: "Long RMSE (m)", accessor: "col4" },
        { Header: "Vert RMSE (m)", accessor: "col5" },
        { Header: "Roll RMSE (deg)", accessor: "col6" },
        { Header: "Pitch RMSE (deg)", accessor: "col7" },
        { Header: "Yaw RMSE (deg)", accessor: "col8" },
        { Header: "Consistency", accessor: "col9" },
        { Header: "Runtime (s)", accessor: "col10" },
        { Header: "Environment", accessor: "col11" },
        { Header: "Authors", accessor: "col12" },
        { Header: "Paper Title", accessor: "col13" },
        { Header: "Venue", accessor: "col14" },
        { Header: "Year", accessor: "col15" }
      );
    } else if (newValue == 0) {
      // 2d localization
      newColumns.push(
        { Header: "Ref Sensor", accessor: "col1" },
        { Header: "Test Sensor", accessor: "col2" },
        { Header: "Lat RMSE (m)", accessor: "col3" },
        { Header: "Long RMSE (m)", accessor: "col4" },
        { Header: "Yaw RMSE (deg)", accessor: "col5" },
        { Header: "Consistency", accessor: "col6" },
        { Header: "Runtime (s)", accessor: "col7" },
        { Header: "Environment", accessor: "col8" },
        { Header: "Authors", accessor: "col9" },
        { Header: "Paper Title", accessor: "col10" },
        { Header: "Venue", accessor: "col11" },
        { Header: "Year", accessor: "col12" }
      );
    } else if (newValue == 4 || newValue == 5) {
      // detection
      newColumns.push(
        { Header: "Car mAP (%)", accessor: "col1" },
        { Header: "Pedestrian mAP (%)", accessor: "col2" },
        { Header: "Cyclist mAP (%)", accessor: "col3" },
        { Header: "Runtime (s)", accessor: "col4" },
        { Header: "Environment", accessor: "col5" },
        { Header: "Authors", accessor: "col6" },
        { Header: "Paper Title", accessor: "col7" },
        { Header: "Venue", accessor: "col8" },
        { Header: "Year", accessor: "col9" }
      );
    }

    // update states
    setColumns(newColumns);
    setData(newData);
    setValue(newValue);
  };

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  if (isLoading) {
    return (
      <section {...props} className={outerClasses}>
        <div>
          <h1> Loading </h1>
          <Spinner color="teal" />
        </div>
      </section>
    );
  } else {
    return (
      <section {...props} className={outerClasses}>
        <div className="container pt-32 px-8 lg:flex flex flex-col">
          {/*Material Tabs*/}
          <Tabs
            position={"relative"}
            value={value}
            variant={"scrollable"}
            scrollButtons="on"
            TabIndicatorProps={{
              style: { background: "cyan" },
            }}
            onChange={handleChange}
          >
            <Tab label="SE(2) Localization" />
            <Tab label="SE(2) Odometry" />
            <Tab label="SE(3) Localization" />
            <Tab label="SE(3) Odometry" />
            <Tab label="3D Detection" />
            <Tab label="BEV Detection" />
          </Tabs>
        </div>

        {/*React Table*/}
        <div className="container table_overflow">
          <table className="min-w-full divide-y divide-gray-200">
            <tbody>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-body font-bold text-gray-900 uppercase tracking-wider"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </tbody>

            <tbody
              className="bg-white divide-y divide-gray-200"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          className="px-6 py-4 bg-white divide-y divide-gray-200 whitespace-nowrap text-sm font-body text-gray-900"
                          {...cell.getCellProps()}
                          colSpan="1"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default App;
