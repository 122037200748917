import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesSplit from '../components/sections/FeaturesSplit';

const Home = () => {

  return (
    <>
      <Hero/>
      <FeaturesSplit invertMobile invertDesktop topDivider imageFill/>
    </>
  );
};

export default Home;