import {map} from "leaflet/dist/leaflet-src.esm";
import ReactTooltip from "react-tooltip";
import React from "react";
import transparent from '../../assets/images/transparent_overlay.png';

function SensorSuite() {
    const [image, setVehicleImage] = React.useState('bg-car')
    return (
        <>
            <map name="workMap">
                <area alt=""
                      style ={{outline: 'none'}}
                      onMouseEnter={() => setVehicleImage('bg-radar')}
                      onMouseLeave={() => setVehicleImage('bg-car')}
                      data-tip data-for='Radar' href="#" shape="rectangle" coords="210, 10, 240, 70"/>
                <area alt=""
                      onMouseOver={() => setVehicleImage('bg-lidar')}
                      onMouseLeave={() => setVehicleImage('bg-car')}
                      data-tip data-for='Lidar' href="#" shape="rectangle" coords="210, 70, 240, 90"/>
                <area alt=""
                      onMouseOver={() => setVehicleImage('bg-gnss')}
                      onMouseLeave={() => setVehicleImage('bg-car')}
                      data-tip data-for='GNSS' shape="rectangle" coords="210, 90, 240, 130"/>
                <area alt=""
                      onMouseOver={() => setVehicleImage('bg-camera')}
                      onMouseLeave={() => setVehicleImage('bg-car')}
                      data-tip data-for='Camera' shape="rectangle" coords="240, 90, 280, 110"/>
            </map>
            <ReactTooltip id='Radar' place='right' type='dark'>
                <p className={"tooltipHeader"}>Radar</p>
                <span>
                    Navtech CIR204-H Radar <br/>

                    &#8226; 0.0438m range resolution <br/>
                    &#8226; Range up to 300m <br/>
                    &#8226; 0.9&#176; horizontal resolution <br/>
                    &#8226; 4Hz <br/>
                </span>
            </ReactTooltip>
            <ReactTooltip id='Lidar' place='right' type='dark'>
                <p className={"tooltipHeader"}>Lidar</p>
                <span>
                    128-beam Velodyne Alpha-Prime Lidar <br/>

                    &#8226; 40&#176; VFOV <br/>
                    &#8226; Range up to 300m <br/>
                    &#8226; 0.1&#176; vertical resolution (variable) <br/>
                    &#8226; 0.2&#176; horizontal resolution <br/>
                    &#8226; 10Hz<br/>
                    &#8226; ~200,000 points per rotation<br/>
                </span>
            </ReactTooltip>
            <ReactTooltip id='GNSS' place='right' type='dark'>
                <p className={"tooltipHeader"}>Post-Processed GNSS</p>
                <span>
                    Applanix POS LV GPS/IMU <br/>

                    &#8226; Includes wheel encoder <br/>
                    &#8226; Includes RTX subscription <br/>
                    &#8226; Post-processed poses accurate to within 5cm RMS <br/>
                </span>
            </ReactTooltip>
            <ReactTooltip id='Camera' place='right' type='dark'>
                <p className={"tooltipHeader"}>Camera</p>
                <span>
                    FLIR Blackfly S Camera <br/>

                    &#8226; 2448x2048 (5 MP) <br/>
                    &#8226; 71&#176; VFOV <br/>
                    &#8226; 81&#176; HFOV <br/>
                    &#8226; 10 Hz <br/>
                </span>
            </ReactTooltip>
            <img src={transparent} className={image} useMap="#workMap" hidefocus={"true"} alt={''}/>
        </>
    )
}

export default SensorSuite;