import React, { useContext, useEffect, useState, createContext } from "react";
import { API } from "aws-amplify";
import * as queries from "./graphql/queries";

const APIContext = createContext();

function APIContextProvider({ children }) {
  // Initialize state
  const [allModels, setAllModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get all benchmarks
        let result = [
          (await API.graphql({ query: queries.listRadarLocModels })).data
            .listRadarLocModels.items,
          (await API.graphql({ query: queries.listRadarOdModels })).data
            .listRadarOdModels.items,
          (await API.graphql({ query: queries.listLidarLocModels })).data
            .listLidarLocModels.items,
          (await API.graphql({ query: queries.listLidarOdModels })).data
            .listLidarOdModels.items,
          (await API.graphql({ query: queries.listLidarDeModels })).data
            .listLidarDeModels.items,
          (await API.graphql({ query: queries.listRadarDeModels })).data
            .listRadarDeModels.items,
        ];
        setAllModels(result);
        setIsLoading(false);
      } catch (e) {
        console.log("error on fetch", e);
      }
    };
    fetchData();
  }, []);

  return (
    <APIContext.Provider value={{ allModels, isLoading }}>
      {children}
    </APIContext.Provider>
  );
}

export default APIContextProvider;

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
