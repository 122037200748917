import React, { useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../utils/SectionProps";
import "../assets/customStyles/styles.css";
import Expand from "react-expand-animated";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const Examples = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertMobile,
  invertDesktop,
  invertColor,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",

    className
  );

  const splitClasses = classNames("split-wrap", alignTop && "align-top");

  const [open, setOpen] = useState([false, false, false, false, false]);
  const transitions = ["height", "opacity", "background"];

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <h1>Examples</h1>
        This page contains links to papers that have used the Boreas dataset.
        <br />
        <br />
        <p>
          <b>
            Radar Odometry Combining Probabilistic Estimation and Unsupervised
            Feature Learning
          </b>
          <br />
          Keenan Burnett, David J. Yoon, Angela P. Schoellig, Timothy D. Barfoot
          <br />
          <i>Robotics: Science and Systems (RSS) 2021</i>
          <br />
          <a href={"http://www.roboticsproceedings.org/rss17/p029.pdf"}>
            [paper]
          </a>{" "}
          &nbsp;
          <a href={"https://www.youtube.com/watch?v=T8MozTG1BT4"}>
            [video]
          </a>{" "}
          &nbsp;
          <a
            onClick={() =>
              setOpen([!open[0], open[1], open[2], open[3], open[4]])
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            [bibtex]
          </a>{" "}
          &nbsp;
          <a href={"https://github.com/utiasASRL/hero_radar_odometry"}>
            [code]
          </a>{" "}
          &nbsp;
          <Expand open={open[0]} duration={300} transitions={transitions}>
            <div
              style={{ height: 200, background: "#212b35", color: "#ffffff" }}
            >
              <pre>
                {`@INPROCEEDINGS{burnett_rss21,
    title={Radar Odometry Combining Probabilistic Estimation and Unsupervised Feature Learning},
    author={Burnett, Keenan and Yoon, David J and Schoellig, Angela P and Barfoot, Timothy D},
    booktitle={Robotics: Science and Systems},
    year={2021}
}`}
              </pre>
            </div>
          </Expand>
        </p>
        <p>
          <b>
            Do We Need to Compensate for Motion Distortion and Doppler Effects
            in Spinning Radar Navigation?
          </b>
          <br />
          Keenan Burnett, Angela P. Schoellig, Timothy D. Barfoot
          <br />
          <i>IEEE Robotics and Automation Letters (RA-L) + ICRA 2021</i>
          <br />
          <a href={"https://ieeexplore.ieee.org/abstract/document/9327473"}>
            [paper]
          </a>{" "}
          &nbsp;
          <a href={"https://youtu.be/fIk_CMG1lho"}>[video]</a> &nbsp;
          <a
            onClick={() =>
              setOpen([open[0], !open[1], open[2], open[3], open[4]])
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            [bibtex]
          </a>{" "}
          &nbsp;
          <a href={"https://github.com/keenan-burnett/yeti_radar_odometry"}>
            [code]
          </a>{" "}
          &nbsp;
          <Expand open={open[1]} duration={300} transitions={transitions}>
            <div
              style={{ height: 300, background: "#212b35", color: "#ffffff" }}
            >
              <pre>
                {`@ARTICLE{burnett_ral21,
  author = {Keenan Burnett, Angela P. Schoellig, Timothy D. Barfoot},
  journal={IEEE Robotics and Automation Letters},
  title={Do We Need to Compensate for Motion Distortion and Doppler Effects in Spinning Radar Navigation?},
  year={2021},
  volume={6},
  number={2},
  pages={771-778},
  doi={10.1109/LRA.2021.3052439}}
}`}
              </pre>
            </div>
          </Expand>
        </p>
        <p>
          <b>
            Are We Ready for Radar to Replace Lidar in All-Weather Mapping and
            Localization?
          </b>
          <br />
          Keenan Burnett, Yuchen Wu, David J. Yoon, Angela P. Schoellig, Timothy
          D. Barfoot
          <br />
          <i>IEEE Robotics and Automation Letters (RA-L) + IROS 2022</i>
          <br />
          <a href={"https://arxiv.org/abs/2203.10174"}>[paper]</a> &nbsp;
          <a href={"https://youtu.be/okS7pF6xX7A"}>[video]</a> &nbsp;
          <a
            onClick={() =>
              setOpen([open[0], open[1], !open[2], open[3], open[4]])
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            [bibtex]
          </a>{" "}
          &nbsp;
          <a href={"https://github.com/utiasASRL/vtr3"}>[code]</a> &nbsp;
          <Expand open={open[2]} duration={300} transitions={transitions}>
            <div
              style={{ height: 300, background: "#212b35", color: "#ffffff" }}
            >
              <pre>
                {`@ARTICLE{burnett_ral22,
  author={Burnett, Keenan and Wu, Yuchen and Yoon, David J. and Schoellig, Angela P. and Barfoot, Timothy D.},
  journal={IEEE Robotics and Automation Letters},
  title={Are We Ready for Radar to Replace Lidar in All-Weather Mapping and Localization?},
  year={2022},
  volume={7},
  number={4},
  pages={10328-10335},
  doi={10.1109/LRA.2022.3192885}}`}
              </pre>
            </div>
          </Expand>
        </p>
        <p>
          <b>
            Picking up speed: Continuous-time Lidar-only odometry using doppler
            velocity measurements
          </b>
          <br />
          Yuchen Wu, David J. Yoon, Keenan Burnett, Soeren Kammel, Yi Chen,
          Heethesh Vhavle, Timothy D. Barfoot
          <br />
          <i>IEEE Robotics and Automation Letters (RA-L) + ICRA 2023</i>
          <br />
          <a href={"https://arxiv.org/abs/2209.03304"}>[paper]</a> &nbsp;
          <a href={"https://youtu.be/l-_pYSeRA1M"}>[video]</a> &nbsp;
          <a
            onClick={() =>
              setOpen([open[0], open[1], open[2], !open[3], open[4]])
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            [bibtex]
          </a>{" "}
          &nbsp;
          <a href={"https://github.com/utiasASRL/steam_icp"}>[code]</a> &nbsp;
          <Expand open={open[3]} duration={300} transitions={transitions}>
            <div
              style={{ height: 300, background: "#212b35", color: "#ffffff" }}
            >
              <pre>
                {`@ARTICLE{wu_ral22,
  author={Wu, Yuchen and Yoon, David J. and Burnett, Keenan and Kammel, Soeren and Chen, Yi and Vhavle, Heethesh and Barfoot, Timothy D.},
  journal={IEEE Robotics and Automation Letters},
  title={Picking up Speed: Continuous-Time Lidar-Only Odometry Using Doppler Velocity Measurements},
  year={2023},
  volume={8},
  number={1},
  pages={264-271},
  doi={10.1109/LRA.2022.3226068}}`}
              </pre>
            </div>
          </Expand>
        </p>
        <p>
          <b>
            Need for Speed: Fast Correspondence-Free Lidar Odometry Using
            Doppler Velocity
          </b>
          <br />
          David J. Yoon, Keenan Burnett, Johann Laconte, Yi Chen, Heethesh
          Vhavle, Soeren Kammel, James Reuther, Timothy D. Barfoot
          <br />
          <i>Submitted to IROS 2023</i>
          <br />
          <a href={"https://arxiv.org/abs/2303.06511"}>[paper]</a> &nbsp;
          <a
            onClick={() =>
              setOpen([open[0], open[1], open[2], open[3], !open[4]])
            }
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            [bibtex]
          </a>{" "}
          &nbsp;
          {/* <a href={'https://github.com/utiasASRL/steam_icp'}>[code]</a> &nbsp; */}
          <Expand open={open[4]} duration={300} transitions={transitions}>
            <div
              style={{ height: 200, background: "#212b35", color: "#ffffff" }}
            >
              <pre>
                {`@misc{yoon_arxiv23,
  author = {Yoon, David J. and Burnett, Keenan and Laconte, Johann and Chen, Yi and Vhavle, Heethesh and Kammel, Soeren and Reuther, James and Barfoot, Timothy D.},
  title = {Need for Speed: Fast Correspondence-Free Lidar Odometry Using Doppler Velocity},
  publisher = {arXiv},
  year = {2023}}`}
              </pre>
            </div>
          </Expand>
        </p>
      </div>
    </section>
  );
};

export default Examples;
