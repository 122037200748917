import React, { useEffect } from "react";
import classNames from "classnames";
import Button from "../components/elements/Button";
import "../assets/customStyles/styles.css";
import sequences from "../components/sections/SequenceList";

import {
  ButtonGroup,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Typography,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Styles for MaterialUI
const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    maxWidth: 800,
    margin: 10,
    backgroundColor: "#d4e5e5",
    fontFamily: "inter",
  },
  media: {
    height: 300,
    boxShadow: 0,
    border: 0,
  },
}));

// Handle and store which checkboxes are currently selected
const handleChange = (name, setSelected, selected, event) => {
  if (event.target.checked) setSelected((selected) => [...selected, name]);
  else {
    setSelected((selected) => selected.filter((item) => item !== name));
  }
};

// Gallery cards with "lazy loading"
const Cards = ({ sequences, setSelected, selected }) => {
  const classes = useStyles();
  return (
    <ul className="port_ul">
      {sequences.map((s) => (
        <Card className={classes.root}>
          {/* Display Youtube Media*/}
          <CardMedia
            border={0}
            className={classes.media}
            component="iframe"
            alt=""
            srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${s.src}/?autoplay=1><img src=https://img.youtube.com/vi/${s.src}/hqdefault.jpg alt=''><span>▶</span></a>`}
          />
          {/* Display Sequence Metadata */}
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {s.title}
            </Typography>
            <Typography>{s.tag.join(", ")}</Typography>
          </CardContent>
          {/* Checkbox */}
          <CardActionArea>
            <CardActions>
              <Checkbox
                style={{ color: "black" }}
                checked={selected.includes(s.title)}
                onChange={(e) =>
                  handleChange(s.title, setSelected, selected, e)
                }
              />
            </CardActions>
          </CardActionArea>
        </Card>
      ))}
    </ul>
  );
};

// List view in a table
const Lists = ({ sequences, setSelected, selected }) => {
  let rows = [];
  // Load Data
  for (let i = 0; i < sequences.length; i++) {
    rows.push({
      sequence: sequences[i].title,
      tags: sequences[i].tag.join(", "),
    });
  }
  return (
    // Generate table with checkboxes
    <table style={{ minWidth: "80vw" }}>
      <thead>
        <tr>
          <th />
          <th>Sequences</th>
          <th>Tags</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.sequence}>
            <td>
              <Checkbox
                style={{ color: "white" }}
                checked={selected.includes(row.sequence)}
                onChange={(e) =>
                  handleChange(row.sequence, setSelected, selected, e)
                }
              />
            </td>
            <td style={{ textAlign: "left" }}>{row.sequence}</td>
            <td style={{ textAlign: "left" }}>{row.tags}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

//Download page with gallery filter
const Download = ({ ...props }) => {
  const outerClasses = classNames("hero section center-content");
  // Load the sequence data located in components/sections/SequenceList.js, contains title, tags, Youtube src
  const [data, setData] = React.useState(sequences);
  // Handle gallery to card state
  const [viewMode, setViewMode] = React.useState("gallery");
  // Handle which checkboxes are selected state
  const [selectionModel, setSelectionModel] = React.useState([]);
  // Options for filtering by sensor
  const [sensors, setSensors] = React.useState({
    all: true,
    lidar: false,
    radar: false,
    camera: false,
  });
  const [allSelected, setAllSelected] = React.useState(false);

  // Function to filter to only return the sequences with tags that include the filter
  const filterItem = (filters) => {
    const updatedData = sequences.filter((idx) => {
      return idx.tag.includes(filters);
    });
    setData(updatedData);
  };
  // Download file function
  const downloadFile = () => {
    // workaround to download txt easily
    const element = document.createElement("a");
    let l = "#!/bin/bash \nroot=. \n";
    // get selected sequences
    selectionModel.map((s) => {
      // build the string for downloading
      l = l + "aws s3 sync s3://boreas/boreas-" + s + " ";
      l += " $root/boreas-" + s + " ";
      if (sensors["all"] === false) {
        l += '--exclude "*" ';
        if (sensors["lidar"]) l += ' --include "lidar/*"';
        if (sensors["radar"]) l += ' --include "radar/*"';
        if (sensors["camera"]) l += ' --include "camera/*"';
        l += ' --include "applanix/*"';
        l += ' --include "calib/*"';
      }
      l += "\n";
    });

    const file = new Blob([l], { type: "text/x-shellscript" });
    element.href = URL.createObjectURL(file);
    element.download = "boreasDatasetDownload.sh";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  // create bar for buttons and download size
  const ButtonBar = () => {
    const [estSize, setEstSize] = React.useState(0);
    const classes = useStyles();
    const sensorDataSize = {
      all: 100,
      lidar: 55,
      radar: 5,
      camera: 40,
    };

    useEffect(() => {
      let tmp_size = 0;
      for (let i = 0; i < Object.values(sensors).length; i++)
        tmp_size +=
          Object.values(sensorDataSize)[i] *
          Object.values(sensors)[i] *
          selectionModel.length;
      setEstSize(tmp_size);
    }, [selectionModel, sensors]);

    const handleChange = (event) => {
      console.log(event.target.name);
      if (event.target.name === "all") {
        setSensors({ all: true, lidar: false, radar: false, camera: false });
      } else {
        setSensors({
          ...sensors,
          [event.target.name]: event.target.checked,
          all: false,
        });
      }
    };

    const selectAll = (event) => {
      if (!allSelected) {
        let complete = [];
        sequences.forEach((sequence) => complete.push(sequence["title"]));
        setSelectionModel(complete);
        setAllSelected(true);
      } else {
        setSelectionModel([]);
        setAllSelected(false);
      }
    };

    return (
      <div className={"mt-16"}>
        <div
          className={"mt-16 mb-32"}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <p
            style={{
              marginLeft: "7vw",
              paddingRight: "5%",
              flex: 0.9,
              textAlign: "left",
            }}
          >
            Check the boxes of sequences you want to download, then click the
            download button when ready.
            <p className={"mt-16"}>
              {" "}
              <a href="https://github.com/utiasASRL/pyboreas/blob/master/download.md">
                Detailed Download Instructions{" "}
              </a>
            </p>
            <p className={"mt-16"}> Estimated Size: {estSize} GB</p>
            <Button onClick={() => downloadFile()}>Download</Button>
          </p>
          <FormGroup className={classes.MuiDataGridRoot} style={{ flex: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "white" }}
                  checked={selectionModel.length === sequences.length}
                  onChange={selectAll}
                  name="select"
                />
              }
              label="Select/Deselect All Sequences"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "white" }}
                  checked={sensors["all"]}
                  onChange={handleChange}
                  name="all"
                />
              }
              label="All Sensors"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "white" }}
                  checked={sensors["lidar"]}
                  onChange={handleChange}
                  name="lidar"
                />
              }
              label="LiDAR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "white" }}
                  checked={sensors["radar"]}
                  onChange={handleChange}
                  name="radar"
                />
              }
              label="Radar"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "white" }}
                  checked={sensors["camera"]}
                  onChange={handleChange}
                  name="camera"
                />
              }
              label="Camera"
            />
          </FormGroup>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <ButtonGroup style={{ flex: 1, marginLeft: "7vw" }}>
            <Button
              className={
                "port-button button-primary reveal-from-bottom is-revealed"
              }
              onClick={() => setViewMode("gallery")}
            >
              Gallery View
            </Button>
            <Button
              className={
                "port-button button-primary reveal-from-bottom is-revealed"
              }
              onClick={() => setViewMode("list")}
            >
              List View
            </Button>
          </ButtonGroup>

          <ButtonGroup style={{ flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }}
            >
              <ButtonGroup style={{ flex: 0 }}>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => setData(sequences)}
                >
                  All
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Sun")}
                >
                  Sun
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Overcast")}
                >
                  Overcast
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Snow")}
                >
                  {" "}
                  Snow{" "}
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Snowing")}
                >
                  {" "}
                  Snowing{" "}
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Clouds")}
                >
                  {" "}
                  Clouds{" "}
                </Button>
              </ButtonGroup>

              <ButtonGroup>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Construction")}
                >
                  {" "}
                  Construction{" "}
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Rain")}
                >
                  {" "}
                  Rain{" "}
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Night")}
                >
                  {" "}
                  Night{" "}
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Alternate")}
                >
                  {" "}
                  Alternate{" "}
                </Button>
                <Button
                  className={
                    "port-button button-secondary reveal-from-bottom is-revealed"
                  }
                  onClick={() => filterItem("Dusk")}
                >
                  {" "}
                  Dusk{" "}
                </Button>
              </ButtonGroup>
            </div>
          </ButtonGroup>
        </div>
      </div>
    );
  };

  if (viewMode === "gallery") {
    return (
      <section {...props} className={outerClasses}>
        {ButtonBar()}
        <div className={"mt-16 reveal-from-bottom is-revealed"}>
          <Cards
            sequences={data}
            setSelected={setSelectionModel}
            selected={selectionModel}
          />
        </div>
      </section>
    );
  } else {
    return (
      <section {...props} className={outerClasses}>
        {ButtonBar()}
        <div
          className={"mt-16 reveal-from-bottom is-revealed"}
          style={{ display: "grid", justifyContent: "center" }}
        >
          <Lists
            sequences={data}
            setSelected={setSelectionModel}
            selected={selectionModel}
          />
        </div>
      </section>
    );
  }
};

export default Download;
