import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
// import ReactGA from 'react-ga';

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
// import Dataset from './views/dataset';
import Leaderboard from "./views/leaderboard";
import Submit from "./views/submit";
import Download from "./views/download";
import APIContextProvider from "./apiContext";
import Examples from "./views/examples";
import contact from "./components/layout/Contact";

// // Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);
//
// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    // const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    // trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <APIContextProvider>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            {/* <AppRoute
              exact
              path="/dataset"
              component={Dataset}
              layout={LayoutDefault}
            /> */}
            <AppRoute
              exact
              path="/download"
              component={Download}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/examples"
              component={Examples}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/submit"
              component={Submit}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/leaderboard"
              component={Leaderboard}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/contact"
              component={contact}
              layout={LayoutDefault}
            />
          </Switch>
        )}
      />
    </APIContextProvider>
  );
};

export default App;
